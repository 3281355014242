import * as React from "react"
import Seo from "../components/seo"
import { injectIntl, useIntl, Link } from "gatsby-plugin-intl"

const NotFoundPage = () => {
  const intl = useIntl()
  return (
    <>
      <Seo title={intl.formatMessage({ id: "notfound.header" })} />
      <main className="h-screen w-screen flex items-center justify-center">
        <div>
          <h1 className="text-2xl">
            {intl.formatMessage({ id: "notfound.header" })}
          </h1>
          <p className="my-5">
            {intl.formatMessage({ id: "notfound.description" })}
          </p>
          <Link className="underline" to="/">
            {intl.formatMessage({ id: "go_home_link" })}
          </Link>
        </div>
      </main>
    </>
  )
}

export default injectIntl(NotFoundPage)
